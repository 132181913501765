<template>
  <div class="foot-box">
    <div class="foot">
      <div class="service-box">
        <div class="left-box">
          <p class="text">
            广州金视教育科技有限公司<span> </span><span>All Rights Reserved </span><span class="br"><span
              class="vertical">｜</span></span> 公司邮箱：gzjsjjkj@163.com <span class="br"><span
              class="vertical">｜</span></span> 公司地址：广州市南沙区丰泽东路106号</p>
          <p>Copyright © 1998-2024<span class="br"><span class="vertical">｜</span></span>
            <a href="https://beian.miit.gov.cn/">
              <span v-if="hostname === 'www.weiqiplayers.com'">
                粤ICP备2022149689号-1
              </span>
              <span v-else>
                粤ICP备2022149689号-2
              </span>
            </a> <span/></p>
        </div>
      </div>
      <div class="qrcode-box">
        <div class="img">
          <img src="http://cdn.dzqyh.com/shoutan-program/web/bg_dzqrcode.png" alt=""/>
          <div>丹朱围棋小程序</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Index",
  components: {},
  data() {
    return { hostname: window.location.hostname }
  }
};
</script>
<style scoped lang='scss'>
a {
  color: white;
  outline-style: none;
  -moz-outline-style: none;
}

.foot-box {
  background-color: #8fc31f;
  background-color: #FFC4B5;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  height: 1rem;
  width: 100%;

  .foot {
    width: 11.48rem;
    min-width: 100%;
    font-size: 0.12rem;
  }
}

p {
  margin: 0;
}

.service-box {
  width: 11.48rem;
  margin: 0 auto;
  display: flex;
  // justify-content: space-between;
  justify-content: center;
  // border: 1px solid red;

  .left-box {
    margin-left: 0.35rem;
    text-align: center;

    p {
      line-height: 0.23rem;

      &:nth-child(3) {
        span {
          display: inline-block;
          width: 0.2rem;
        }

        a {
          &:last-child {
            &::before {
              content: "";
              background: url("http://cdn.dzqyh.com/shoutan-program/web/icon_filing .png") no-repeat;
              background-size: contain;
              background-position: center center;
              width: 0.2rem;
              height: 0.2rem;
              display: inline-block;
              vertical-align: middle;
            }

            img {
              margin-right: 0.06rem;
            }
          }
        }
      }
    }

    .text {
      font-size: 0.15rem;
    }
  }

  .right-box {
    margin-right: 0.35rem;

    div {
      height: 0.2rem;
    }
  }
}

.qrcode-box {
  display: none;
}

@media only screen and (min-width: 501px) and (max-width: 1148px) {
  .foot-box {
    min-width: 11.48rem;
  }
  .service-box {
    justify-content: center;
  }
}

@media only screen and (min-width: 200px) and (max-width: 500px) {
  .foot-box {
    padding: 0.2rem 0;
    min-width: 3.75rem;
    width: 100%;
    height: 0.7rem;
  }

  .service-box {
    width: 8rem;
    min-width: 1.65rem;
    flex-direction: column-reverse;
    margin-left: 0.2rem;

    .left-box,
    .right-box {
      margin: 0;
      font-size: 0.12rem;
    }

    .left-box {
      p {
        line-height: 0.14rem;
      }

      .text {
        font-size: 0.1rem;
      }

      .br {
        display: block;

        .vertical {
          display: none;
        }
      }
    }

    .right-box {
      display: none;
    }
  }

  .foot {
    width: 3.75rem;
    font-size: 0.05rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 0.2rem;
  }

  .qrcode-box {
    display: flex;
    text-align: center;
    width: 2.6rem;
    justify-content: space-between;

    .img {
      img {
        width: 0.6rem;
        height: 0.6rem;
        background-color: #fff;
      }
    }
  }
}
</style> 
